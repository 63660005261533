import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`This is subject to change in response to contextual reality and identified curricular priorities.`}</em></p>
    <h2 {...{
      "id": "psi-poi-2021---2022",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#psi-poi-2021---2022",
        "aria-label": "psi poi 2021   2022 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PSI POI 2021 - 2022`}</h2>
    <Table fullWidth innerWidth={800} mdxType="Table">
      <table style={{
        "width": "92%"
      }}>
    <colgroup>
        <col style={{
            "width": "23%"
          }} />
        <col style={{
            "width": "23%"
          }} />
        <col style={{
            "width": "23%"
          }} />
        <col style={{
            "width": "23%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><strong>EC3 &amp; 4</strong></th>
        <th><strong>EC3 &amp; 4</strong>
            <strong>Year Long (Dip in)</strong></th>
        <th><strong>EC3 &amp; 4</strong></th>
        <th><strong>EC3 &amp; 4</strong>
            <strong>Year Long (Dip in)</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr className="odd">
        <td><strong>Who we are</strong>
            An inquiry into the nature of the self; beliefs and values; personal, physical mental, social and
                spiritual health; human relationships including families, friends, communities, and cultures; rights and
                responsibilities; what it means to be human</td>
        <td><strong>How We Express Ourselves</strong><br />
            An inquiry into the ways in which we discover and express ideas, feelings, nature, culture, beliefs, and
            values; the ways in which we reflect on, extend and enjoy our creativity; our appreciation of the aesthetic.
        </td>
        <td><strong>How We Organise Ourselves<br />
        </strong>An inquiry into the interconnectedness of human-made systems and communities; the structure and
            function of organizations; societal decision-making; economic activities and their impact on humankind and
            the environment.</td>
        <td><strong>Sharing the Planet</strong>
            An inquiry into rights and responsibilities in the struggle to share finite resources with other
                people and with other living things; communities and the relationships within and between them; access
                to equal opportunities; peace and conflict resolution</td>
    </tr>
    <tr className="even">
        <td>
            PSPE, Language, Mathematics, Social Studies (Social Organisation and Culture), Arts, Science (living
                things).</td>
        <td><strong>Subject Focus:</strong>
            PSPE, Language, Mathematics, Social Studies (Social Organisation and Culture), Arts.</td>
        <td><strong>Subject Focus:</strong>
            Language, Mathematics, Social Studies (Human Systems and Economic Activities), Arts.</td>
        <td><strong>Subject Focus:</strong>
            PSPE, Science (Earth and Space), Social Studies (Resources and the environment), Language,
                Mathematics.</td>
    </tr>
    <tr className="odd">
        <td><strong>Central Idea:</strong>
            Our understanding of who we are is developed through exploration and play.</td>
        <td><strong>Central idea:</strong>
            People express their ideas creatively through stories.</td>
        <td><strong>Central Idea:</strong>
            People use different forms of transport for different reasons.</td>
        <td><strong>Central Idea:</strong>
            Our personal choices can affect and change our environment.</td>
    </tr>
    <tr className="even">
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>What play and exploration look like.</li>
                <li>What we learn when playing and exploring.</li>
                <li>Our senses as a way of collecting information.</li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry:</strong>
            <ul>
                <li>Ways to tell a story.</li>
                <li>Reasons to tell a story.</li>
                <li>The ways in which we respond to stories.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>The specific purposes of different transportation systems.</li>
                <li>The factors that affect the kinds of transport available.</li>
                <li>How transport meets the needs of the community.</li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry:</strong>
            <ul>
                <li>Earth’s resources (including water).</li>
                <li>Choices that can help sustain the environment.</li>
                <li>The impact of reducing, reusing and recycling materials and resources.</li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><strong>Key Concepts:</strong>
            Form, Function, Change.</td>
        <td><strong>Key Concepts:</strong>
            Form, Causation, Perspective.</td>
        <td><strong>Key Concepts</strong>:
            Causation, Function, Form.</td>
        <td><strong>Key Concepts</strong>:
            Form, Responsibility, Connection.</td>
    </tr>
    <tr className="even">
        <td><strong>Related Concepts:</strong>
            Growth, Cooperation, Conflict, Culture, Exploration.
            <strong>Approaches to Learning:</strong>
            <strong>All ATL</strong>’s are taught within this unit however emphasis is given to
                <strong>Research</strong> skills during this unit.
            <strong>Learner Profile:</strong>
            Knowledgeable, Inquirers, Reflective.
            <strong>PYP Attitude Development:</strong>
            Appreciation, Integrity, Curiosity, Independence.</td>
        <td><strong>Related Concepts:</strong>
            Design, Pattern, Imagination, Interpretation.
            <strong>Approaches to Learning:</strong>
            <strong>All ATL’</strong>s are taught within this unit however emphasis is given to
                <strong>Communication</strong> skills during this unit.
            <strong>Learner Profile:</strong>
            Communicators, Risk-takers, Reflective, Open-minded.
            <strong>PYP Attitudes:</strong>
            Creativity, Confidence, Cooperation, Enthusiasm.</td>
        <td><strong>Related Concepts:</strong>
            Efficiency, Mechanics, Physics, Pollution, Technological Advances.
            <strong>Approaches to Learning:</strong>
            <strong>All ATL</strong>’s are taught within this unit however emphasis is given to <strong>Self
                Management</strong> skills during this unit.
            <strong>Learner Profile:</strong>
            Inquirers, Thinkers, Knowledgeable, Reflective.
            <strong>PYP Attitudes Development:</strong>
            Creativity, Curiosity.</td>
        <td><strong>Related Concepts:</strong>
            interdependence, conservation
            <strong>Approaches to Learning:</strong>
            <strong>All ATL</strong>’s are taught within this unit however emphasis is given to
                <strong>Thinking</strong> skills during this unit.
            <strong>Learner Profile:</strong>
            Balanced, Caring, Open minded, Principled, Reflective
            <strong>PYP Attitudes</strong>
            Appreciation, Commitment, Respect, Empathy, Tolerance</td>
    </tr>
    </tbody>
      </table>
      <table style={{
        "width": "92%"
      }}>
    <colgroup>
        <col style={{
            "width": "23%"
          }} />
        <col style={{
            "width": "23%"
          }} />
        <col style={{
            "width": "23%"
          }} />
        <col style={{
            "width": "23%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><strong>Kindergarten</strong>
            <strong>#1</strong></th>
        <th><strong>Kindergarten</strong>
            <strong>#2</strong></th>
        <th><strong>Kindergarten</strong>
            <strong>#3</strong></th>
        <th><strong>Kindergarten</strong>
            <strong>#4</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr className="odd">
        <td><strong>Who we are</strong>
            An inquiry into the nature of the self; beliefs and values; personal, physical, mental, social and
                spiritual health; human relationships including families, friends, communities, and cultures; rights and
                responsibilities; what it means to be human.</td>
        <td><strong>How we express ourselves</strong>
            An inquiry into the ways in which we discover and express ideas, feelings, nature, culture, beliefs,
                and values; the ways in which we reflect on, extend and enjoy our creativity; our appreciation of the
                aesthetic.</td>
        <td><strong>How the world works</strong>
            An inquiry into the natural world and its laws; the interaction between the natural world (physical
                and biological) and human societies; how humans use their understanding of scientific principles; the
                impact of scientific and technological advances on society and on the environment.</td>
        <td><strong>Sharing the Planet</strong>
            An inquiry into rights and responsibilities in the struggle to share finite resources with other
                people and with other living things; communities and the relationships within and between them; access
                to equal opportunities; peace and conflict resolution.</td>
    </tr>
    <tr className="even">
        <td><strong>Subject Focus:</strong>
            Social Studies, (Human systems and economic activities), PSPE (interactions).</td>
        <td><strong>Subject Focus:</strong>
            Arts, PSPE, Language.</td>
        <td><strong>Subject Focus:</strong>
            Science, (Materials and Matter) Mathematics.</td>
        <td><strong>Subject Focus:</strong>
            Science, (Living things) Language.</td>
    </tr>
    <tr className="odd">
        <td><strong>Central Idea:</strong>
            People work together to find effective solutions to problems to promote a collaborative community.
        </td>
        <td><strong>Central Idea:</strong>
            People use different materials and resources to express feelings, ideas and understanding.</td>
        <td><strong>Central Idea:</strong>
            People observe, inquire into and learn from the world around them.</td>
        <td><strong>Central Idea:</strong>
            Plants are a life-sustaining resource, shared with and affected by other living things.</td>
    </tr>
    <tr className="even">
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>The ways in which we belong.</li>
                <li>How group norms reflect the values of the community.</li>
                <li>How emotions can affect ourselves and others.</li>
                <li>The ways in which we collaboratively solve problems.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>Forms of expression.</li>
                <li>Possibilities with particular materials.</li>
                <li>Different emotions.</li>
                <li>Representing ideas and feelings.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>The ways in which we develop an inquiry.</li>
                <li>The resources we can use to carry out inquiries.</li>
                <li>How to use the scientific method to develop an experiment.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>All living things have similar needs.</li>
                <li>Humans’ responsibility for all living things.</li>
                <li>The interdependence of plants and other living things.</li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><strong>Key Concepts:</strong>
            Function, Connection, Responsibility.</td>
        <td><strong>Key Concepts:</strong>
            Form, Perspective.</td>
        <td><strong>Key Concepts:</strong>
            Causation, Change.</td>
        <td><strong>Key Concepts:</strong>
            Responsibility, Connection, Causation.</td>
    </tr>
    <tr className="even">
        <td rowSpan="4"><strong>Related Concepts:</strong>
            Self awareness &amp; belonging, community, communication, cooperation.
            <strong>Approaches to Learning:</strong>
            Communication Skills, Social skills, Self-management skills.
            <strong>Learner Profile:</strong>
            Balanced, Communicator, Principled, Caring.
            <strong>PYP Attitude Development:</strong>
            Independence, Empathy, Cooperation</td>
        <td rowSpan="4"><strong>Related Concepts:</strong>
            Creating and responding.
            <strong>Approaches to Learning:</strong>
            Communication skills, Self management Skills.
            <strong>Learner Profile:</strong>
            Reflective, Open-minded.
            <strong>PYP Attitude Development:</strong>
            Appreciation, Tolerance, Creativity</td>
        <td rowSpan="4"><strong>Related Concepts:</strong>
            Changes of state, chemical &amp; physical changes.
            <strong>Approaches to Learning:</strong>
            Research skills, Thinking Skills.
            <strong>Learner Profile:</strong>
            Inquirer, Thinker, Risk-taker.
            <strong>PYP Attitude Development:</strong>
            Curiosity, Commitment, Confidence.</td>
        <td rowSpan="4"><strong>Related Concepts:</strong>
            Interdependence, conservation, sustainability.
            <strong>Approaches to Learning:</strong>
            Research skills, Thinking Skills.
            <strong>Learner Profile:</strong>
            Caring, Knowledgeable.
            <strong>PYP Attitude Development:</strong>
            Respect, Appreciation.</td>
    </tr>
    <tr className="odd"></tr>
    <tr className="even"></tr>
    <tr className="odd"></tr>
    </tbody>
      </table>
      <table>
    <colgroup>
        <col style={{
            "width": "17%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><strong>Grade 1</strong>
            <strong>#1</strong></th>
        <th><strong>Grade 1</strong>
            <strong>#6</strong></th>
        <th><strong>Grade 1</strong>
            <strong>#4</strong></th>
        <th><strong>Grade 1</strong>
            <strong>#3</strong></th>
        <th><strong>Grade 1</strong>
            <strong>#2</strong></th>
        <th><strong>Grade 1</strong>
            <strong>#5</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr className="odd">
        <td><strong>Who we are</strong>
            An inquiry into the nature of the self; beliefs and values; personal, physical, mental, social and
                spiritual health; human relationships including families, friends, communities, and cultures; rights and
                responsibilities; what it means to be human.</td>
        <td><strong>Where we are in place and time</strong>
            An inquiry into orientation in place and time; personal histories; homes and journeys; the
                discoveries, explorations and migrations of humankind; the relationships between and the
                interconnectedness of individuals and civilizations, from local and global perspectives.</td>
        <td><strong>How we express ourselves</strong>
            An inquiry into the ways in which we discover and express ideas, feelings, nature, culture, beliefs,
                and values; the ways in which we reflect on, extend and enjoy our creativity; our appreciation of the
                aesthetic.</td>
        <td><strong>How the world works</strong>
            An inquiry into the natural world and its laws; the interaction between the natural world (physical
                and biological) and human societies; how humans use their understanding of scientific principles; the
                impact of scientific and technological advances on society and on the environment.</td>
        <td><strong>How we organise ourselves</strong>
            An inquiry into the interconnectedness of human-made systems and communities; the structure and
                function of organizations; societal decision-making; economic activities and their impact on humankind
                and the environment.</td>
        <td><strong>Sharing the Planet</strong>
            An inquiry into rights and responsibilities in the struggle to share finite resources with other
                people and with other living things; communities and the relationships within and between them; access
                to equal opportunities; peace and conflict resolution.</td>
    </tr>
    <tr className="even">
        <td><strong>Subject Focus:</strong>
            PSPE (Interactions), Language
            Social Studies (Social Organisation and Culture).</td>
        <td><strong>Subject Focus:</strong>
            Social Studies(Social Organisation and Culture), language, mathematics.</td>
        <td><strong>Subject Focus</strong>:
            Arts, Language
            Science: (Living things)</td>
        <td><strong>Subject Focus:</strong>
            Science, (Forces and Energy) Mathematics, Language.</td>
        <td><strong>Subject Focus:</strong>
            Social Studies, (Human systems), Language, Mathematics.</td>
        <td><strong>Subject Focus:</strong>
            Science (Living things),
            Social Studies: Human and Natural Environments, Mathematics.</td>
    </tr>
    <tr className="odd">
        <td><strong>Central idea:</strong>
            The choices we make affect people around us.</td>
        <td><strong>Central idea:</strong>
            Knowing where we are and where we’re from helps us to develop a sense of our own identity and place in
                the world.</td>
        <td><strong>Central Idea:</strong>
            Stories can engage their audience and communicate meaning.</td>
        <td><strong>Central Idea:</strong>
            Using our knowledge of forces and motion allows us to create and innovate.</td>
        <td><strong>Central Idea:</strong>
            Human made systems allow for movement.</td>
        <td><strong>Central idea</strong>
            There are challenges in sharing the planet amongst people and animals.</td>
    </tr>
    <tr className="even">
        <td><strong>Lines of Inquiry</strong>
            <ul>
                <li>The choices we make impact others.</li>
                <li>Each person brings value to the community.</li>
                <li>Actions people take can make a positive or negative impact.</li>
                <li>Making balanced choices.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>Our current location in the world.</li>
                <li>The relationship between our location and other parts of the world.</li>
                <li>The ways in which people live around the world.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>What makes an effective story.</li>
                <li>What stories do to us.</li>
                <li>The ways in which stories are created and shared.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>The forces present in our everyday lives.</li>
                <li>The ways simple machines work.</li>
                <li>How forces are used to solve problems.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>Forms of movement.</li>
                <li>Human made systems.</li>
                <li>The ways in which systems work. (Interlinking- interdependence- coordination)</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>The ways in which animals adapt to challenges they face around the world.</li>
                <li>The different relationships between animals and humans around the world.</li>
                <li>How human and animal communities impact on each other.</li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><strong>Key Concepts:</strong>
            Responsibility, Function, Causation.</td>
        <td><strong>Key concepts:</strong>
            Connection, Perspective, Change.</td>
        <td><strong>Key Concepts:</strong>
            Form, Function, Perspective.</td>
        <td><strong>Key Concepts</strong>:
            Form, Function, Causation.</td>
        <td><strong>Key Concepts:</strong>
            Form, Function, Responsibility.</td>
        <td><strong>Key Concepts:</strong>
            Form, Connection, Change.</td>
    </tr>
    <tr className="even">
        <td><strong>Related Concepts</strong>:
            Cooperation.
            <strong>Approaches to Learning:</strong>
            Self Management Skills<br />
                Research Skills.
            <strong>Learner Profile Development:</strong>
            Principled, Risk-Taker.
            <strong>PYP Attitudes Development:</strong>
            Cooperation, Respect.</td>
        <td><strong>Related concepts</strong>:
            Geography, Landscape, Impact.
            <strong>Approaches to Learning:</strong>
            Research, Thinking.
            <strong>Learner Profile Development:</strong>
            Thinker, Communicator.
            <strong>PYP Attitudes Development:</strong>
            Confidence, Tolerance, Respect.</td>
        <td><strong>Related Concepts:</strong>
            Creating, Responding.
            <strong>Approaches to Learning:</strong>
            Communication Skills, Thinking skills, Social Skills.
            <strong>Learner Profile Development:</strong>
            Reflective, Open-minded.
            <strong>PYP Attitudes Development:</strong>
            Appreciation, Enthusiasm, Creativity.</td>
        <td><strong>Related Concepts:</strong>
            Physics, Power.
            <strong>Approaches to Learning:</strong>
            Research skills, Thinking Skills.
            <strong>Learner Profile Development:</strong>
            Inquirer, Thinker.
            <strong>PYP Attitudes Development:</strong>
            Curiosity, Independence.</td>
        <td><strong>Related Concepts:</strong> Communication, Cooperation.
            <strong>Approaches to Learning</strong>
            Social Skills, Communication skills, Self - Management Skills.
            <strong>Learner Profile Development:</strong>
            Caring, Principled.
            <strong>PYP Attitudes Development:</strong>
            Cooperation, Integrity, Commitment.</td>
        <td><strong>Related Concepts:</strong>
            Conservation, Adaptation.
            <strong>Approaches to Learning</strong>
            Research, Thinking.
            <strong>Learner Profile Development:</strong>
            Caring, Knowledgeable.
            <strong>PYP Attitudes Development:</strong>
            Empathy, Respect.</td>
    </tr>
    </tbody>
      </table>
      <table style={{
        "width": "95%"
      }}>
    <colgroup>
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "19%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><strong>Grade 2</strong>
            <strong># 4</strong></th>
        <th><strong>Grade 2</strong>
            <strong>#3</strong></th>
        <th><strong>Grade 2</strong>
            <strong># 1</strong></th>
        <th><strong>Grade 2</strong>
            <strong># Year long (Dip in)</strong></th>
        <th><strong>Grade 2</strong>
            <strong># 2</strong></th>
        <th><strong>Grade 2</strong>
            <strong># 5</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr className="odd">
        <td><strong>Who we are</strong>
            An inquiry into the nature of the self; beliefs and values; personal, physical, mental, social and
                spiritual health; human relationships including families, friends, communities, and cultures; rights and
                responsibilities; what it means to be human.</td>
        <td><strong>Where we are in place and time</strong>
            An inquiry into orientation in place and time; personal histories; homes and journeys; the
                discoveries, explorations and migrations of humankind; the relationships between and the
                interconnectedness of individuals and civilizations, from local and global perspectives.</td>
        <td><strong>How we express ourselves</strong>
            An inquiry into the ways in which we discover and express ideas, feelings, nature, culture, beliefs,
                and values; the ways in which we reflect on, extend and enjoy our creativity; our appreciation of the
                aesthetic.</td>
        <td><strong>How the world works</strong>
            An inquiry into the natural world and its laws; the interaction between the natural world (physical
                and biological) and human societies; how humans use their understanding of scientific principles; the
                impact of scientific and technological advances on society and on the environment.</td>
        <td><strong>How we organise ourselves</strong>
            An inquiry into the interconnectedness of human-made systems and communities; the structure and
                function of organizations; societal decision-making; economic activities and their impact on humankind
                and the environment.</td>
        <td><strong>Sharing the Planet</strong>
            An inquiry into rights and responsibilities in the struggle to share finite resources with other
                people and with other living things<strong>;</strong> communities and the relationships within and
                between them; access to equal opportunities; peace and conflict resolution.</td>
    </tr>
    <tr className="even">
        <td><strong>Subject Focus</strong>:
            PSPE (Identity, Active Living, Interactions), Mathematics, Language, Science (Living Things), social
                studies.</td>
        <td><h3 id="subject-focus">Subject Focus: </h3>
            <h3 id="social-studies-human-and-natural-environments-science-living-things-mathematics.">Social Studies
                (Human and Natural Environments), Science (Living Things), Mathematics.</h3></td>
        <td><h3 id="subject-focus-1">Subject Focus: </h3>
            <h3 id="language-pspe-arts-drama-visual-social-studies-social-organization-and-culture.">Language, PSPE,
                Arts (Drama, Visual), Social Studies (Social organization and culture).</h3></td>
        <td><strong>Subject Focus</strong>:
            Science (Earth and Space), Mathematics, Language.</td>
        <td><strong>Subject Focus:</strong>
            Science, (Earth and Space) Social Studies (Human systems and economic activity), Language,
                Mathematics.</td>
        <td><strong>Subject Focu</strong>s:
            Social Studies (Resources and the environment), Science (Living things/ Materials and Matter),
                Mathematics.</td>
    </tr>
    <tr className="odd">
        <td><strong>Central Idea</strong>:
            The effective interactions between human body systems contribute to health and survival.</td>
        <td><h3 id="central-idea">Central Idea: </h3>
            <h3 id="migrations-involve-challenges-and-opportunities.">Migrations involve challenges and
                opportunities.</h3></td>
        <td><h3 id="central-idea-1">Central idea: </h3>
            <h3 id="the-desire-to-be-understood-can-help-us-develop-ourselves-into-effective-communicators.">The desire
                to be understood can help us develop ourselves into effective communicators.</h3></td>
        <td><strong>Central Idea:</strong>
            Curiosities about nature can lead to discoveries.</td>
        <td><strong>Central idea:</strong>
            Measurement systems are used in our daily lives to help how we organise ourselves.</td>
        <td><h3 id="central-idea-2">Central Idea: </h3>
            <h3 id="water-is-a-limited-resource-which-is-essential-to-life.">Water is a limited resource which is
                essential to life.</h3></td>
    </tr>
    <tr className="even">
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>Human body systems.</li>
                <li>How body systems work together.</li>
                <li>Choices for a healthy lifestyle.</li>
            </ul>
        </td>
        <td><h3 id="lines-of-inquiry">Lines of inquiry:</h3>
            <ul>
                <li>Reasons for migration.</li>
                <li>Migration stories.</li>
                <li>The ways in which groups respond to migration.</li>
                <li>Challenges, risks or opportunities brought with migration.</li>
            </ul>
        </td>
        <td><h3 id="lines-of-inquiry-1">Lines of inquiry:</h3>
            <ul>
                <li><h3 id="what-language-is.">What language is.</h3></li>
                <li><h3 id="why-people-communicate.">Why people communicate.</h3></li>
                <li><h3 id="how-we-communicate.">How we communicate.</h3></li>
                <li><h3 id="what-effective-communication-is.">What effective communication is.</h3></li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>The impact of earth changes on all living things.</li>
                <li>The importance of observation in nature.</li>
                <li>Patterns and cycles within nature.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>Different measurement systems (time, temperature, money, linear measurement, volume, speed
                    (weather)).</li>
                <li>The connection between measurement systems.</li>
                <li>How measurement systems help us meet our needs.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>Sources of water and how water is used.</li>
                <li>Distribution and availability of usable water.</li>
                <li>Human impact and responsibilities regarding water.</li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><strong>Key concepts:</strong>
            Function, Causation.</td>
        <td><h3 id="key-concepts">Key Concepts: </h3>
            <h3 id="connection-causation-change.">Connection, Causation, Change.</h3></td>
        <td><h3 id="key-concepts-1">Key Concepts: </h3>
            <h3 id="form-perspective.">Form, Perspective.</h3></td>
        <td><strong>Key concepts</strong>:
            Causation, Change, Connection, Function.</td>
        <td><strong>Key Concepts:</strong>
            Function, Connection, Form.</td>
        <td><strong>Key Concepts:</strong>
            Perspective, Responsibility, Causation.</td>
    </tr>
    <tr className="even">
        <td><strong>Related concepts:</strong>
            Balance, Well-being.
            <strong>Approaches to Learning:</strong>
            Social Skills, Self Management.
            <strong>Learner Profile Development:</strong>
            Balanced, Principled.
            <strong>PYP Attitude Development:</strong>
            Commitment, Respect.</td>
        <td><h3 id="related-concepts">Related Concepts: </h3>
            <h3 id="risk-geography-impact.">Risk, Geography, Impact.</h3>
            <h3 id="approaches-to-learning">Approaches to Learning:</h3>
            <h3 id="research-skills-communication-skills.">Research Skills, Communication Skills.</h3>
            <h3 id="learner-profile-development">Learner Profile Development:</h3>
            <h3 id="risk-taker-open-minded.">Risk Taker, Open-minded.</h3>
            <h3 id="pyp-attitude-development">PYP Attitude Development:</h3>
            <h3 id="tolerance-empathy.">Tolerance, Empathy.</h3></td>
        <td><h3 id="related-concepts-1">Related Concepts: </h3>
            <h3 id="creativity-communication.">Creativity, Communication.</h3>
            <h3 id="approaches-to-learning-1">Approaches to Learning:</h3>
            <h3 id="communication-skills-social-skills.">Communication Skills, Social Skills.</h3>
            <h3 id="learner-profile-development-1">Learner Profile Development:</h3>
            <h3 id="communicators-thinker.">Communicators, Thinker.</h3>
            <h3 id="pyp-attitude-development-1">PYP Attitude Development:</h3>
            Creativity, Confidence, Empathy.</td>
        <td><strong>Related concepts:</strong>
            Geology, Adaptation (Cycles), Weather, energy.
            <strong>Approaches to Learning:</strong>
            Research Skills, Thinking Skills, Science Skills.
            <strong>Learner Profile Development:</strong>
            Knowledgeable, Inquirer
            <strong>PYP Attitude Development:</strong>
            Curiosity, Enthusiasm.</td>
        <td><strong>Related Concepts:</strong>
            Systems, Cooperation.
            <strong>Approaches to Learning:</strong>
            Social skills, Self Management Skills.
            <strong>Learner Profile Development:</strong>
            Communicator, Thinkers.
            <strong>PYP Attitude Development:</strong>
            Commitment, Cooperation.</td>
        <td><strong>Related Concepts:</strong> Conservation, Consumption.
            <strong>Approaches to Learning:</strong>
            Thinking, Research, Science, Social Studies.
            <strong>Learner Profile Development:</strong>
            Knowledgeable, Caring.
            <strong>PYP Attitude Development:</strong>
            Appreciation, Integrity.</td>
    </tr>
    </tbody>
      </table>
      <table style={{
        "width": "96%"
      }}>
    <colgroup>
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "15%"
          }} />
        <col style={{
            "width": "17%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><strong>Grade 3</strong>
            <strong>#2</strong></th>
        <th><strong>Grade 3</strong>
            <strong>#3</strong></th>
        <th><strong>Grade 3</strong>
            <strong>#4</strong></th>
        <th><strong>Grade 3</strong>
            <strong>#5</strong></th>
        <th><strong>Grade 3</strong>
            <strong>#1</strong></th>
        <th><strong>Grade 3</strong>
            <strong>#6 Year Long (Dip in)</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr className="odd">
        <td><strong>Who we are</strong>
            An inquiry into the nature of the self; beliefs and values; personal, physical, mental, social and
                spiritual health; human relationships including families, friends, communities, and cultures; rights and
                responsibilities; what it means to be human.</td>
        <td><strong>Where we are in place and time</strong>
            An inquiry into orientation in place and time; personal histories; homes and journeys; the
                discoveries, explorations and migrations of humankind; the relationships between and the
                interconnectedness of individuals and civilizations, from local and global perspectives.</td>
        <td><strong>How we express ourselves</strong>
            An inquiry into the ways in which we discover and express ideas, feelings, nature, culture, beliefs,
                and values; the ways in which we reflect on, extend and enjoy our creativity; our appreciation of the
                aesthetic.</td>
        <td><strong>How the world works</strong>
            An inquiry into the natural world and its laws; the interaction between the natural world (physical
                and biological) and human societies; how humans use their understanding of scientific principles; the
                impact of scientific and technological advances on society and on the environment.*</td>
        <td><strong>How we organise ourselves</strong>
            An inquiry into the interconnectedness of human-made systems and communities; the structure and
                function of organizations; societal decision-making; economic activities and their impact on humankind
                and the environment.</td>
        <td><strong>Sharing the planet</strong>
            An inquiry into rights and responsibilities in the struggle to share finite resources with other
                people and with other living things; communities and the relationships within and between them; access
                to equal opportunities; peace and conflict resolution.</td>
    </tr>
    <tr className="even">
        <td><h3 id="subject-focus-2">Subject Focus: </h3>
            <h3 id="pspe-identity-interactions-social-studies-social-organisation-and-culture.">PSPE (Identity,
                Interactions), Social Studies (Social organisation and culture).</h3></td>
        <td><strong>Subject Focus:</strong>
            Social Studies (Continuity and change through time), Art, Language.</td>
        <td><strong>Subject Focus:</strong>
            Arts (Visual), PSPE, Social Studies (Social organisation and culture).</td>
        <td><strong>Subject Focus:</strong>
            Science (Living things), Language, Mathematics, Social studies (Human and natural environment).</td>
        <td><strong>Subject Focus:</strong>
            Social Studies (Human systems and economic activities), Language, PSPE.</td>
        <td><strong>Subject Focus:</strong>
            Social Studies (Human and natural environments), Mathematics.</td>
    </tr>
    <tr className="odd">
        <td><h3 id="central-idea-3">Central Idea: </h3>
            <h3 id="we-all-share-responsibility-in-ensuring-peoples-rights-are-met.">We all share responsibility in
                ensuring people’s rights are met. </h3></td>
        <td><strong>Central idea:</strong>
            Interpretation of artifacts contributes to our understanding of people's histories.</td>
        <td><strong>Central idea:</strong>
            Creating and responding to art develops an understanding of ourselves and the world around us.</td>
        <td><strong>Central Idea:</strong>
            Cycles in nature impact humans and their environment.</td>
        <td><strong>Central Idea:</strong>
            People work together to make an impact.</td>
        <td><strong>Central Idea:</strong>
            People can make choices to support the sustainability of the Earth’s resources.</td>
    </tr>
    <tr className="even">
        <td><h3 id="lines-of-inquiry-2">Lines of inquiry:</h3>
            <ul>
                <li><h3 id="the-connections-between-rights-and-responsibilities.">The connections between rights and
                    responsibilities.</h3></li>
                <li><h3 id="the-differences-between-rights-and-privileges.">The differences between rights and
                    privileges.</h3></li>
                <li><h3 id="what-responsibilities-do-we-have-to-ensure-everyones-rights-are-met.">What responsibilities
                    do we have to ensure everyone’s rights are met.</h3></li>
                <li><h3 id="what-happens-if-and-when-people-dont-meet-fulfill-their-responsibilities.">What happens if
                    and when people don't meet / fulfill their responsibilities.</h3></li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry:</strong>
            <ul>
                <li>Why people make, keep or discard artifacts.</li>
                <li>Using artifacts to collect and analyse evidence about the past.</li>
                <li>How artifacts change to meet the needs of the time.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry</strong>
            <ul>
                <li>The contexts in which artwork was created (the purpose of art).</li>
                <li>How learning about the arts develops appreciation (the art elements).</li>
                <li>Personal preference in appreciation of the arts (art appreciation).</li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry:</strong>
            <ul>
                <li>How the natural world is changed by weather cycles and natural disasters.</li>
                <li>How humans modify their surroundings in order to survive.</li>
                <li>Need for action after a natural disaster to rebuild communities.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry:</strong>
            <ul>
                <li>The ways in which people organize themselves.</li>
                <li>The ways in which people share responsibilities.</li>
                <li>The ways in which people identify problems and work to find solutions.</li>
            </ul>
        </td>
        <td><strong>Lines of inquiry</strong>
            <ul>
                <li>Earth’s renewable and non-renewable resources.</li>
                <li>The impact of people’s choices on the environment.</li>
                <li>The balance between meeting human needs and the use of non-renewable resources.</li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><h3 id="key-concepts-2">Key Concepts: </h3>
            <h3 id="form-function-perspective-.">Form, Function, Perspective .</h3></td>
        <td><strong>Key Concepts:</strong>
            Connection, Causation, Change.</td>
        <td><strong>Key Concepts:</strong>
            Form, Perspective.</td>
        <td><strong>Key Concepts:</strong>
            Causation, Change, Responsibility.</td>
        <td><strong>Key Concepts:</strong>
            Connection, Function.</td>
        <td><strong>Key concepts</strong>:
            Causation, Perspective, Responsibility.</td>
    </tr>
    <tr className="even">
        <td><h3 id="related-concepts-2">Related Concepts: </h3>
            <h3 id="fairness-needs-wants.">Fairness, Needs, Wants.</h3>
            <h3 id="section-9"></h3>
            <h3 id="approaches-to-learning-2">Approaches to Learning:</h3>
            <h3 id="self-management-skills">Self management Skills,</h3>
            <h3 id="social-skills."> Social Skills.</h3>
            <h3 id="section-10"></h3>
            <h3 id="learner-profile-development-2">Learner Profile development:</h3>
            <h3 id="open-minded-balanced.">Open-minded, Balanced.</h3>
            <h3 id="section-11"></h3>
            <h3 id="pyp-attitudes-development">PYP Attitudes development:</h3>
            <h3 id="commitment-empathy.">Commitment, Empathy.</h3></td>
        <td><strong>Related Concepts:</strong>
            Chronology, History.
            <strong>Approaches to Learning</strong>
            Communication Skills, Research Skills.
            <strong>Learner Profile development:</strong>
            Inquirer, Knowledgeable.
            <strong>PYP Attitudes development:</strong>
            Enthusiasm, Tolerance<strong>.</strong></td>
        <td><strong>Related Concepts:</strong>
            Forms of Communication, Self-expression.
            <strong>Approaches to Learning:</strong>
            Communication Skills.
            <strong>Learner Profile development:</strong>
            Reflective, Risk-taker.
            <strong>PYP Attitudes development:</strong>
            Appreciation, Confidence.</td>
        <td><strong>Related Concepts</strong>:
            Prediction, Organisation and Destruction.
            <strong>Approaches to Learning:</strong>
            Research skills, Thinking skills.
            <strong>Learner Profile development:</strong>
            Knowledgeable, Inquirer.
            <strong>PYP Attitudes development:</strong>
            Curiosity, Independence.</td>
        <td><strong>Related Concepts</strong>:
            Communication.
            <strong>Approaches to Learning:</strong>
            Self-management, Thinking skills.
            <strong>Learner Profile development:</strong>
            Thinker, Communicator.
            <strong>PYP Attitudes development:</strong>
            Cooperation, Creativity.</td>
        <td><strong>Related concepts:</strong>
            Protection, Interdependence.
            <strong>Approaches to Learning:</strong>
            Communication skills, Social skills.
            <strong>Learner Profile development:</strong>
            Principled, Caring.
            <strong>PYP Attitudes development:</strong>
            Respect, Integrity.</td>
    </tr>
    </tbody>
      </table>
      <table style={{
        "width": "100%"
      }}>
    <colgroup>
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><strong>Grade 4</strong>
            <strong>#4</strong></th>
        <th><strong>Grade 4</strong>
            <strong>#6</strong></th>
        <th><strong>Grade 4</strong>
            <strong>#1 Year Long (Dip in)</strong></th>
        <th><strong>Grade 4</strong>
            <strong>#3</strong></th>
        <th><strong>Grade 4</strong>
            <strong>#5</strong></th>
        <th><strong>Grade 4</strong>
            <strong>#2</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr className="odd">
        <td><strong>Who we are</strong>
            An inquiry into the nature of the self; beliefs and values; personal, physical, mental, social and
                spiritual health; human relationships including families, friends, communities, and cultures; rights and
                responsibilities; what it means to be human.</td>
        <td><strong>Where we are in place and time</strong>
            An inquiry into orientation in place and time; personal histories; homes and journeys; the
                discoveries, explorations and migrations of humankind; the relationships between and the
                interconnectedness of individuals and civilizations, from local and global perspectives.</td>
        <td><strong>How we express ourselves</strong>
            An inquiry into the ways in which we discover and express ideas, feelings, nature, culture, beliefs,
                and values; the ways in which we reflect on, extend and enjoy our creativity; our appreciation of the
                aesthetic.</td>
        <td><strong>How the world works</strong>
            An inquiry into the natural world and its laws; the interaction between the natural world (physical
                and biological) and human societies; how humans use their understanding of scientific principles; the
                impact of scientific and technological advances on society and on the environment.</td>
        <td><strong>How we organise ourselves</strong>
            An inquiry into the interconnectedness of human-made systems and communities; the structure and
                function of organisations; societal decision-making; economic activities and their impact on humankind
                and the environment.</td>
        <td><strong>Sharing the Planet</strong>
            An inquiry into rights and responsibilities in the struggle to share finite resources with other
                people and with other living things; communities and the relationships within and between them; access
                to equal opportunities; peace and conflict resolution.</td>
    </tr>
    <tr className="even">
        <td><h3 id="subject-focus-3">Subject Focus: </h3>
            <h3 id="pspe-identity-interactions-mathematics-social-studies-social-organisation-and-culture.">PSPE
                (Identity, Interactions), Mathematics, Social Studies (Social organisation and culture).</h3></td>
        <td><h3 id="subject-focus-4">Subject Focus:</h3>
            <h3 id="social-studies-continuity-and-change-through-time-language-science-materials-and-matter.">Social
                Studies (Continuity and Change through Time), Language, Science (Materials and Matter).</h3></td>
        <td><h5 id="subject-focus-5"><strong>Subject Focus:</strong></h5><h5 id="arts-language-pspe.">Arts, Language,
            PSPE.</h5></td>
        <td><strong>Subject Focus:</strong>
            Science (Forces and Energy), Mathematics, Language.</td>
        <td><h3 id="subject-focus-6">Subject Focus: </h3>
            <h3 id="social-studies-human-systems-and-economic-activity-mathematics-science-skills.">Social Studies
                (Human systems and economic activity), Mathematics, Science (skills).</h3></td>
        <td><h3 id="subject-focus-7">Subject Focus: </h3>
            <h3 id="social-studies-social-organisation-and-culture-pspe-interactions-language-science-living-things.">
                Social Studies (Social organisation and culture), PSPE (interactions), Language, Science (Living
                Things).</h3></td>
    </tr>
    <tr className="odd">
        <td><h3 id="central-idea-4">Central idea: </h3>
            <h3 id="peoples-beliefs-and-values-affect-how-they-act-and-live.">People’s beliefs and values affect how
                they act and live.</h3></td>
        <td><h3 id="central-idea-5">Central idea: </h3>
            <h3 id="exploration-leads-to-discoveries-and-new-understandings.">Exploration leads to discoveries, and new
                understandings.</h3></td>
        <td><h5 id="central-idea-6"><strong>Central Idea:</strong></h5><h5 id="the-process-of-creating-allows-us-to-express-ourselves-in-a-variety-of-formats-and-to-learn-more-about-ourselves-and-others.">
            The process of creating allows us to express ourselves in a variety of formats and to learn more about
            ourselves and others.</h5></td>
        <td><strong>Central Idea:</strong>
            Energy may be converted from one form to another and used to solve problems.</td>
        <td><h3 id="central-idea-7">Central Idea:</h3>
            <h3 id="economic-activity-relies-on-the-exchange-and-consumption-of-goods-and-services.">Economic activity
                relies on the exchange and consumption of goods and services.</h3></td>
        <td><h3 id="central-idea-8">Central idea: </h3>
            <h3 id="finding-peaceful-solutions-to-conflict-leads-to-a-better-quality-of-human-life.">Finding peaceful
                solutions to conflict leads to a better quality of human life.</h3></td>
    </tr>
    <tr className="even">
        <td><h3 id="lines-of-inquiry-3">Lines of inquiry:</h3>
            <ul>
                <li><h3 id="peoples-beliefs-and-values.">People's beliefs and values.</h3></li>
                <li><h3 id="our-identity-is-shaped-by-our-beliefs-and-values.">Our identity is shaped by our beliefs and
                    values.</h3></li>
                <li><h3 id="the-ways-in-which-our-actions-can-be-determined-by-our-beliefs.">The ways in which our
                    actions can be determined by our beliefs.</h3></li>
            </ul>
        </td>
        <td><h3 id="lines-of-inquiry-4">Lines of inquiry:</h3>
            <ul>
                <li><h3 id="the-motivations-that-drive-people-to-explore.">The motivations that drive people to
                    explore.</h3></li>
                <li><h3 id="changes-have-taken-place-because-of-past-explorations."> Changes have taken place because of
                    past explorations.</h3></li>
                <li><h3 id="the-impact-of-technology-on-exploration.">The impact of technology on exploration.</h3></li>
            </ul>
        </td>
        <td><h5 id="lines-of-inquiry-5"><strong>Lines of Inquiry:</strong></h5>
            <ul>
                <li><h5 id="the-creative-process.">The creative process.</h5></li>
                <li><h5 id="the-many-forms-that-art-can-take.">The many forms that art can take.</h5></li>
                <li><h5 id="the-impact-of-reflection-on-creativity.">The impact of reflection on creativity.</h5></li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry:</strong>
            <ul>
                <li>The various forms of energy.</li>
                <li>The ways in which energy is used.</li>
                <li>The ways in which energy can be transformed.</li>
            </ul>
        </td>
        <td><h3 id="lines-of-inquiry-6">Lines of Inquiry:</h3>
            <ul>
                <li><h3 id="the-variety-of-activities-that-happen-in-the-local-and-global-marketplace.">The variety of
                    activities that happen in the local and global marketplace.</h3></li>
                <li><h3 id="the-connection-between-supply-and-demand.">The connection between supply and demand.</h3>
                </li>
                <li><h3 id="the-impact-of-trade-on-society.">The impact of trade on society.</h3></li>
            </ul>
            <h3 id="section-12"></h3></td>
        <td><h3 id="lines-of-inquiry-7">Lines of Inquiry</h3>
            <ul>
                <li><h3 id="living-and-working-together-peacefully-affects-our-community.">Living and working together
                    peacefully affects our community.</h3></li>
                <li><h3 id="understanding-the-causes-of-a-conflict-can-help-determine-its-solution.">Understanding the
                    causes of a conflict can help determine its solution.</h3></li>
                <li><h3 id="our-actions-affect-the-wellbeing-of-our-community.">Our actions affect the wellbeing of our
                    community.</h3></li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><h3 id="key-concepts-3">Key Concepts: </h3>
            <h3 id="perspective-responsibility.">Perspective, Responsibility.</h3></td>
        <td><h3 id="key-concepts-4">Key Concepts: </h3>
            <h3 id="causation-change.">Causation, Change.</h3></td>
        <td><h5 id="key-concepts-5"><strong>Key Concepts:</strong></h5><h5 id="form-reflection-perspective.">Form,
            Reflection, Perspective.</h5></td>
        <td><strong>Key Concepts:</strong>
            Form, Function, Change.</td>
        <td><h3 id="key-concepts-6">Key Concepts: </h3>
            <h3 id="function-connection.">Function, Connection.</h3></td>
        <td><h3 id="key-concepts-7">Key Concepts: </h3>
            <h3 id="causation-perspective-responsibility.">Causation, Perspective, Responsibility.</h3></td>
    </tr>
    <tr className="even">
        <td><h3 id="related-concepts-3">Related Concepts: </h3>
            <h3 id="diversity-spirituality.">Diversity, Spirituality.</h3>
            <h3 id="section-13"></h3>
            <h3 id="approaches-to-learning-3">Approaches to Learning:</h3>
            <h3 id="social-skills-research-skills-communication-skills.">Social Skills, Research skills, Communication
                Skills.</h3>
            <h3 id="section-14"></h3>
            <h3 id="learner-profile-development-3">Learner Profile Development:</h3>
            <h3 id="open-minded-principled.">Open-minded, Principled.</h3>
            <h3 id="section-15"></h3>
            <h3 id="pyp-attitude-development-2">PYP Attitude Development:</h3>
            <h3 id="tolerance-empathy.-1">Tolerance, Empathy.</h3></td>
        <td><h3 id="related-concepts-4">Related Concepts: </h3>
            <h3 id="time-progress.">Time, Progress.</h3>
            <h3 id="section-16"></h3>
            <h3 id="approaches-to-learning-4">Approaches to Learning:</h3>
            <h3 id="thinking-skills-research-skills.">Thinking Skills, Research skills.</h3>
            <h3 id="section-17"></h3>
            <h3 id="section-18"></h3>
            <h3 id="learner-profile-development-4">Learner Profile Development:</h3>
            <h3 id="knowledgeable-inquirer.">Knowledgeable, Inquirer.</h3>
            <h3 id="section-19"></h3>
            <h3 id="pyp-attitude-development-3">PYP Attitude Development:</h3>
            <h3 id="confidence-independence.">Confidence, Independence.</h3></td>
        <td><h5 id="related-concepts-5"><strong>Related Concepts:</strong></h5><h5 id="appreciation-criticism.">
            Appreciation, Criticism.</h5><h5 id="section-20"></h5><h5 id="approaches-to-learning-5"><strong>Approaches
            to Learning:</strong></h5><h5 id="communication-skills">Communication Skills</h5><h5 id="thinking-skills--dialectical-thought.">Thinking skills- Dialectical thought.</h5><h5 id="section-21"></h5><h5 id="learner-profile-development-5"><strong>Learner Profile
            Development:</strong></h5><h5 id="communicator-reflective.">Communicator, Reflective.</h5><h5 id="section-22"></h5><h5 id="pyp-attitude-development-4"><strong>PYP Attitude Development:</strong></h5>
            <h5 id="creativity-enthusiasm-appreciation.">Creativity, Enthusiasm, Appreciation.</h5></td>
        <td><strong>Related Concepts:</strong>
            Forms of energy, Conservation of Energy, Transformation of Energy.
            <strong>Approaches to Learning:</strong>
            Thinking Skills, Self Management Skills, Research skills.
            <strong>Learner Profile Development:</strong>
            Inquirers, Risk Taker.
            <strong>PYP Attitude Development:</strong>
            Curiosity, Commitment.</td>
        <td><h3 id="related-concepts-6">Related Concepts: </h3>
            <h3 id="employment-production.">Employment, Production.</h3>
            <h3 id="section-23"></h3>
            <h3 id="approaches-to-learning-6">Approaches to Learning:</h3>
            <h3 id="thinking-skills-research-skills.-1">Thinking Skills, Research skills. </h3>
            <h3 id="section-24"></h3>
            <h3 id="learner-profile-development-6">Learner Profile Development:</h3>
            <h3 id="thinker-reflective.">Thinker, Reflective.</h3>
            <h3 id="section-25"></h3>
            <h3 id="pyp-attitude-development-5">PYP Attitude Development:</h3>
            <h3 id="curiosity-cooperation.">Curiosity, Cooperation.</h3></td>
        <td><h3 id="related-concepts-7">Related Concepts: </h3>
            <h3 id="justice-rights.">Justice, Rights.</h3>
            <h3 id="section-26"></h3>
            <h3 id="approaches-to-learning-7">Approaches to Learning:</h3>
            <h3 id="self-management-social-skills.">Self-management, Social skills.</h3>
            <h3 id="learner-profile-development-caring-balanced.">Learner Profile Development: Caring, Balanced.</h3>
            <h3 id="pyp-attitude-development-respect-integrity.">PYP Attitude Development: Respect, Integrity.</h3></td>
    </tr>
    </tbody>
      </table>
      <table>
    <colgroup>
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "16%"
          }} />
        <col style={{
            "width": "18%"
          }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><strong>Grade 5</strong>
            <strong>#3</strong></th>
        <th><strong>Grade 5</strong>
            <strong>#1</strong></th>
        <th><strong>Grade 5</strong>
            <strong>#6</strong></th>
        <th><strong>Grade 5</strong>
            <strong>#2</strong></th>
        <th><strong>Grade 5</strong>
            <strong>#4</strong></th>
        <th><strong>Grade 5</strong>
            <strong>#5</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr className="odd">
        <td><strong>Who we are</strong>
            An inquiry into the nature of the self; beliefs and values; personal, physical, mental, social and
                spiritual health; human relationships including families, friends, communities, and cultures; rights and
                responsibilities; what it means to be human.</td>
        <td><strong>Where we are in place and time</strong>
            An inquiry into orientation in place and time; personal histories; homes and journeys; the
                discoveries, explorations and migrations of humankind; the relationships between and the
                interconnectedness of individuals and civilizations, from local and global perspectives.</td>
        <td><strong>How we express ourselves</strong>
            An inquiry into the ways in which we discover and express ideas, feelings, nature, culture, beliefs,
                and values; the ways in which we reflect on, extend and enjoy our creativity; our appreciation of the
                aesthetic.</td>
        <td><strong>How the world works</strong>
            An inquiry into the natural world and its laws; the interaction between the natural world (physical
                and biological) and human societies; how humans use their understanding of scientific principles; the
                impact of scientific and technological advances on society and on the environment.</td>
        <td><strong>How we organise ourselves</strong>
            An inquiry into the interconnectedness of human-made systems and communities; the structure and
                function of organizations; societal decision-making; economic activities and their impact on humankind
                and the environment.</td>
        <td><strong>Sharing the Planet</strong>
            An inquiry into rights and responsibilities in the struggle to share finite resources with other
                people and with other living things; communities and the relationships within and between them; access
                to equal opportunities; peace and conflict resolution.</td>
    </tr>
    <tr className="even">
        <td><strong>Subject Focus</strong>:
            PSPE (Identity, Active Living, Interactions), Science, Mathematics, Language.</td>
        <td><strong>Subject Focus</strong>:
            Social Studies (Continuity and Change Through Time), Language, Mathematics.</td>
        <td><strong>Subject Focus</strong>:
            Language, Visual Arts.</td>
        <td><h5 id="subject-focus-8"><strong>Subject Focus:</strong></h5><h5 id="science-matter-and-materials-mathematics-language.">Science (Matter and Materials), Mathematics,
            Language.</h5></td>
        <td><strong>Subject Focus</strong>:
            Social Studies (Human systems and economic activities), Language.</td>
        <td><strong>PYP Exhibition</strong></td>
    </tr>
    <tr className="odd">
        <td><strong>Central Idea</strong>:
            Understanding of the changes that occur during adolescence helps us become more responsible.</td>
        <td><h3 id="central-idea-9">Central Idea: </h3>
            <h3 id="past-civilizations-have-left-behind-evidence-that-contributes-to-our-understanding-of-progress.">
                Past civilizations have left behind evidence that contributes to our understanding of progress.</h3>
        </td>
        <td><strong>Central Idea:</strong>
            We discover more about ourselves and others through appreciating the ways in which we express
                ourselves.</td>
        <td><h5 id="central-idea-10"><strong>Central Idea:</strong></h5><h5 id="physical-and-chemical-changes-can-have-an-impact-on-people-and-the-environment.">Physical and
            chemical changes can have an impact on people and the environment.</h5></td>
        <td><strong>Central Idea:</strong>
            Systems and decisions can promote or deny equal opportunities and social justice.</td>
        <td><strong>Central Idea:</strong>
            My passion can be connected to local and global issues and the Action I take may lead to positive
                change.</td>
    </tr>
    <tr className="even">
        <td><strong>Lines of Inquiry</strong>:
            <ul>
                <li>Changes that occur as we grow older.</li>
                <li>Responsibility to ourselves and to others.</li>
                <li>Adolescents have a variety of different experiences around the world.</li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry</strong>:
            <ul>
                <li>Researchers use a variety of evidence to learn about the past.</li>
                <li>Civilisations are composed of many different parts.</li>
                <li>Progress has had numerous effects on civilization.</li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry</strong>:
            <ul>
                <li>There are a variety of ways to express oneself.</li>
                <li>Social media can be used to express our ideas and feelings.</li>
                <li>Expressing ourselves can help us understand our own identity and values.</li>
            </ul>
        </td>
        <td><h5 id="lines-of-inquiry-8"><strong>Lines of Inquiry:</strong></h5>
            <ul>
                <li><h5 id="the-scientific-method-can-be-applied-to-different-investigations.">The scientific method can
                    be applied to different investigations.</h5></li>
                <li><h5 id="understanding-chemicals-and-how-they-are-used.">Understanding chemicals and how they are
                    used.</h5></li>
                <li><h5 id="identifying-chemical-or-physical-reactions-in-environmental-issues.">Identifying chemical or
                    physical reactions in environmental issues.</h5></li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry</strong>:
            <ul>
                <li>Organisational systems.</li>
                <li>Responsibilities of organisations.</li>
                <li>Social issues.</li>
            </ul>
        </td>
        <td><strong>Lines of Inquiry:</strong>
            <ul>
                <li>Personal passions can be connected to both local and global issues.</li>
                <li>Learning about a local issue helps us develop a perspective on global issues.</li>
                <li>Action can lead to change.</li>
            </ul>
        </td>
    </tr>
    <tr className="odd">
        <td><strong>Key Concepts:</strong>
            Change, Connection.</td>
        <td><strong>Key Concepts:</strong>
            Change, Connection.</td>
        <td><strong>Key Concepts:</strong>
            Perspective, Connection.</td>
        <td><h5 id="key-concepts-8"><strong>Key Concepts:</strong></h5><h5 id="form-function-responsibility.">Form,
            Function, Responsibility.</h5></td>
        <td><strong>Key Concepts:</strong>
            Perspective<strong>,</strong> Function, Responsibility.</td>
        <td><strong>Key Concepts:</strong>
            Form, Function, Causation, Change, Connection, Perspective, Responsibility.</td>
    </tr>
    <tr className="even">
        <td><strong>Related Concepts:</strong>
            Growth, Responsibility, Identity.
            <strong>Approaches to Learning:</strong>
            Self Management Skills, Social Skills.
            <strong>Learner Profile development:</strong>
            Reflective, Balanced.
            <strong>PYP Attitudes development:</strong>
            Empathy, Respect.</td>
        <td><strong>Related Concepts:</strong>
            Chronology, Progress.
            <strong>Approaches to Learning:</strong>
            Research Skills, Thinking Skills.
            <strong>Learner Profile development:</strong>
            Knowledgeable.
            <strong>PYP Attitudes development:</strong>
            Commitment, Enthusiasm.</td>
        <td><strong>Related Concepts:</strong>
            Imagination, Communication, Appreciation.
            <strong>Approaches to Learning:</strong>
            Communication skills.
            <strong>Learner Profile development:</strong>
            Communicator.
            <strong>PYP Attitudes development:</strong>
            Creativity, Appreciation.</td>
        <td><h5 id="related-concepts-8"><strong>Related Concepts:</strong></h5><h5 id="changes-of-state-reaction.">
            Changes of state, Reaction.</h5><h5 id="section-27"></h5><h5 id="approaches-to-learning-8"><strong>Approaches
            to Learning:</strong></h5><h5 id="thinking-skills-communication-skills.">Thinking Skills, Communication
            Skills.</h5><h5 id="section-28"></h5>
            <strong>Learner Profile development:</strong><h5 id="inquirer.">Inquirer.</h5><h5 id="section-29"></h5>
            <strong>PYP Attitudes development:</strong><h5 id="curiosity.">Curiosity.</h5></td>
        <td><strong>Related Concepts:</strong>
            Government, Education, Rights, Justice, Fairness, Decision Making.
            <strong>Approaches to Learning:</strong>
            Research Skills, Social Skills.
            <strong>Learner Profile development:</strong>
            Principled, Open-minded.
            <strong>PYP Attitudes development:</strong>
            Independence, Integrity.</td>
        <td><strong>Related Concepts:</strong>
            Agency- learner voice, choice and ownership in this inquiry.
            <strong>Approaches to Learning:</strong>
            Communication Skills, Research Skills, Thinking Skills, Self management Skills, Social Skills.
            <strong>Learner Profile development:</strong>
            All Learner Profile attributes.
            <strong>PYP Attitudes development:</strong>
            All PYP attitude characteristics.</td>
    </tr>
    </tbody>
      </table>
    </Table>
    <h2 {...{
      "id": "whole-school-glossary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whole-school-glossary",
        "aria-label": "whole school glossary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Whole School Glossary`}</h2>
    <Table fullWidth innerWidth={700} mdxType="Table">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}><strong parentName="th"><em parentName="strong">{`Term`}</em></strong></th>
            <th parentName="tr" {...{
              "align": null
            }}><strong parentName="th"><em parentName="strong">{`Definition`}</em></strong></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`AGM`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Annual General Meeting, which is open to all members of the School community who wish to hear a summary of the School’s progress against its strategic goals, as well as to hear announcements for the next year, including the election of new Board members`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`CEESA`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Central and Eastern European Schools Association - a coalition of schools in this region that provide athletic and academic activities for Secondary students as well as professional development and support for teachers`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`CIS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Council for International Schools, one of the School’s accrediting organisations`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Differentiation`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Planning for different learning needs and styles`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`EAL`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`English as an Additional Language`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`ECA`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Extra-curricular activities - A programme for students that provides them with opportunities to explore the arts, sports, IT, games, academic enrichment and leadership beyond the classroom`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`ELT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Educational Leadership Team (director, principals, deputy principals, IB coordinators)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`GDPR`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The European General Data Protection Regulations (GDPR) provide strict guidelines that help ensure that student, staff and parent information - in print or digitally - is kept secure and private.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`HOD`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Head of Department`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`IB`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`International Baccalaureate, one of the School’s accrediting organisations and the base of the School’s curricular programmes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`IT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Information Technology`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`LP`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Learner Profile - a key component of the IB that focuses on student attitudes towards self and others. A list of the terms and their definitions is included in all major handbooks`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`LST`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Learning Resource Team, usually the appropriate building principal and counsellor, along with the learning resource teacher. This team meets weekly to discuss students at risk in order to create strategies to support their social, emotional and academic growth`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`MAP`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Measure of Academic Progress, an on-line test for students in reading and mathematics given twice a year to students in Grades 3-8.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Mother tongue`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The language(s) learned first; the language(s) identified with as a ‘native’ speaker; the language known best; the language used most`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`MT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Management Team - Educational Leadership Team (see above) plus Business Manager and IT director`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`MyIB`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`My IB is the International Baccalaureate's new central location for IB resources and applications. You can access the services you use with one username and password.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`MyPSI`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`MyPSI is where parents go to look at their student’s grades (secondary), report cards and standardized testing results`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`NEASC`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New England Association of Schools and Colleges, one of the School’s accrediting organisations`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`OPI`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The On-line Placement Inventory is the assessment tool in maths and English used for Secondary student admissions`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`PA`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Professional Assistants, who support the two Principals and the Director`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`PE / PHE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Physical Education or Physical and Health Education - a part of the EC - Grade 10 curriculum focusing on sports, physical activity and a healthy lifestyle`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`PRC`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The International Baccalaureate's Programme Resource Centre- an online password protected part of MyIB where teacher resources are accessible.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`PSI`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pechersk School International`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`PTC`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Parent teacher conferences, held in both Primary and Secondary`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`SEN`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Special Educational Needs`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`SLC / SLS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Student-led conferences or student-led showcases, held in both Primary and Secondary. Students present to parents their online and paper portfolios showcasing what and how they have learnt`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`TA`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Teaching Assistants, who provide support in the classroom for Primary teachers and some Secondary departments`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`TS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Teaching Support staff, who provide clerical support for teaching and administrative staff`}</td>
          </tr>
        </tbody>
      </table>
    </Table>
    <h2 {...{
      "id": "primary-school--primary-years-programme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#primary-school--primary-years-programme",
        "aria-label": "primary school  primary years programme permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Primary School / Primary Years Programme`}</h2>
    <Table fullWidth innerWidth={700} mdxType="Table">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}><strong parentName="th"><em parentName="strong">{`Term`}</em></strong></th>
            <th parentName="tr" {...{
              "align": null
            }}><strong parentName="th"><em parentName="strong">{`Definition`}</em></strong></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`AL`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Additional Language (Russian, Ukrainian, Spanish)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`B.D.P.E.`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Beginning, Developing, Proficient, Extending - descriptors for reporting to parents and students`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`CI`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Central Idea - the overarching conceptual understanding in the Unit of Inquiry`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`EC`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Early Childhood (ages 3 - 5 years old)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`HTTW`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`How the world works. One of six transdisciplinary themes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`HWEO`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`How we express ourselves. One of six transdisciplinary themes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`HWOO`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`How we organise ourselves. One of six transdisciplinary themes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`KG`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Kindergarten, the programme for five-year-olds (before Grade 1)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`POI`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Programme of Inquiry, consisting of the 6 units of inquiry implemented in each child’s classroom during one school year`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`PSPE / PHE`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Personal, Social and Physical Education - one of the six subject areas in the PYP, Physical Health Education- interchangeable term as PE department is whole school`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`PYP`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Primary Years Programme`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`SS`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Social studies. One of the six subject areas in the PYP`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`SST`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Single Subject Teachers. These are Additional Language teachers (Russian, Ukrainian, Spanish, French, EAL), Music, Visual Arts and Physical Education teachers`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`STP`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Sharing the planet. One of six transdisciplinary themes.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`TT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Transdisciplinary theme. The Programme of Inquiry has 6 transdisciplinary themes under which the School develops its Programme of Inquiry. Each grade has units under each theme, except for 3-5 year olds (EC), who have four units each year.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`UOI`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Unit of Inquiry. This is the 4-6 week long unit of study in each child’s classroom`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`WWA`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Who we are. One of six transdisciplinary themes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`WWAPT`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Where we are in place and time. One of six transdisciplinary themes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`YAAG`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Year at a glance document that outlines all of the planned learning in each child’s classroom this year`}</td>
          </tr>
        </tbody>
      </table>
    </Table>
    <p>{`For more information about the IB PYP in general, visit the `}<a parentName="p" {...{
        "href": "http://ibo.org/pyp/"
      }}><u>{`IB PYP website`}</u></a>{`. For more info about the PYP at PSI,`}</p>
    <p>{`visit the `}<a parentName="p" {...{
        "href": "https://www.psi.kiev.ua/page.cfm?p=534&LockSSL=true"
      }}><u>{`PSI website page`}</u></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      